
// Fonts
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700');

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

body {
	$position: relative;

	$height: 100vh;
  $overflow: hidden;
}
h1 {
	font-weight: bold;
  color: #fff;
	font-size: 60px;
	text-shadow: 0 0 6px #000;
}
h2 {
	margin-top: 0px;
	margin-bottom: 0px;
}

.product_media {
	position: relative;
	margin-bottom: 30px;
	&_show {
		position: absolute;
		left: 0;
		top: 0;
		background: rgba(255,255,255,0.8);
		padding: 0px 6px;
		border-bottom-right-radius: 8px;
		z-index: 1;
	}
	&_show input {
		margin-top: 2px;
		position: relative;
		top: 2px;
	}
	&_show label {
		font-size: 0.8em;
		font-weight: bold;
	}
	&_title {
		display: block;
		width: 100%;
		font-size: 0.7em;
		margin-top: -30px;
	}
	&_sort {
		z-index: 1;
		position: absolute;
		bottom: 0;
		left: 0;
		background: yellow;
		border-top-right-radius: 8px;
		width: 60px;
		padding: 4px 6px;
		font-size: 0.8em;
    font-weight: bold;
		border: 0px;

		&:focus {
			padding: 6px 8px;
		}
	}

	&_delete {
		z-index: 1;
		position: absolute;
		bottom: 0;
		right: 0;
		background: red;
		color: #fff;
		text-transform: uppercase;
		padding: 4px 6px;
		border-top-left-radius: 8px;
		font-size: 0.8em;
    font-weight: bold;

		&:hover {
			color: #fff;
			text-decoration: none;
			padding: 6px 8px;
		}
	}

	&_youtube_play {
		background: url('/images/youtube_play.svg') no-repeat;
		height: 50%;
		background-size: 44%;
		z-index: 1;
		position: absolute;
		top: 31%;
		left: 35%;
		width: 70%;
	}
}
.ratio-4-3{
  width:100%;
  position:relative;
  background:url() 50% 50% no-repeat;
  background-size:cover;
  background-clip:content-box;

	&:before{
		display:block;
	    content:"";
	    padding-top:75%;
	}
}
.panel-body h3 {
	margin-top: -10px;
	margin-left: 14px;
	font-weight: bold;
	text-transform: uppercase;
}
